:root {
  --nav-bg-color: #1b1c1d;
  --nav-bg-active-color: #2c2c2c;
  --results-best-color: rgba(4, 248, 4, 0.185);
}


@import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,400;1,200;1,400&display=swap');

* {
  font-family: 'Exo', sans-serif;
  font-weight:400;
}


.ui.header.quote{
  font-family: 'Exo', sans-serif;
  font-size: 2.2em;
  font-weight:400;

}



.ui.segment.totalTable{
  font-size: .8em !important;
}



.ui.header.aboutHead{
  font-family: 'Exo', sans-serif !important;
  font-weight:700 !important;
  font-size: 3em !important;
}

.ui.header.green{
margin-top:1px !important;
padding-top: 1px !important;
}


.ui.icon.header.homePage{
  font-family: 'Exo', sans-serif !important;
  font-weight:700 !important;
  font-size: 1.6em !important;
}

.ui.menu{
  font-family: 'Exo', sans-serif !important;
  font-weight:700 !important;
  font-size: 1.3em !important;
}
p{
  font-size: 1.5em !important;
  line-height: 1.5 !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul{
  list-style-type: disc;
}
li{
  padding: 4px 0px;;
}
table{
  width: 100%;
  background: #fff;
  margin: 1em 0;
  border: 1px solid rgba(34,36,38,.15);
  box-shadow: none;
  border-radius: .28571429rem;
  text-align: left;
  color: rgba(0,0,0,.87);
  border-collapse: separate;
  border-spacing: 0;
}
th, td{
  border: .3px solid rgba(34,36,38,.15);
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 3px;
  padding-right: 3px;

}

table.results > tr > th{
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 3px;
  padding-right: 3px;

}

td.center.aligned{
  text-align: center;
}

thead{
  background-color: #f5f5f5;
  font-size: 1.1rem;
}
thead > tr > td.best{
  background-color:var(--results-best-color);
  font-size: 1.1rem;
  font-weight: bold;
}
table.planInfo{
  text-align: left;
}
table.results{
  font-size: 1.2em !important;
}

 td > .ui.input > input {
  text-align: left;
  width: 100%;
  min-width: 70px;
  border: none;
  border-color: transparent;
}

table.results > tr > td.best {
  background-color: var(--results-best-color);
}

input:focus{
  outline: none;
}

.dollar.icon{
  left: -10px !important;
}

.ui.left.icon.input > input {
  padding-left: 1.1em !important;
  border: none;
  border-color: transparent;
}

.homeBody{
  position: relative;
  background-color: rgba(248, 248, 248, 0.411);
}




@media all and (max-width:630px) {
  #desktop{
    display: none;

  }
  #dropdown{
    background-color: transparent ;
    color:rgb(247, 247, 247);
    border-radius: 0;
    border-color: black;

  }
  .home{
    position: relative;
    margin-top: -3em;
    margin-left:-25%;
    margin-right:-25%;
    height:350px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ui.icon.header.homePage{
    font-family: 'Exo', sans-serif !important;
    font-weight:700 !important;
    font-size: 1em !important;
  }

  .ui.very.padded.segment{
    padding: 1em ;
  }

  .item {
    text-align: left!important;
  }
  .ui.button{
    border-radius: 0;
  }
  .ui.dropdown.MobileDropdown .menu{
    position: inherit;
    background-color: transparent;
  }
  .left.menu.transition.left.visible{
    margin-top: 10px !important;
  }
  .ui.vertical.menu .dropdown.item .menu {
    margin-left: -10% !important;
    margin-right: -10% !important;
    background-color:transparent;
    }

  .item > a{
      color: white;
  }

  .ui.active.visible.compact.item.dropdown.MobileDropdown {
    background-color: transparent;
    }
  .ui.tiny.attached.segment.navbar{
    background-color: var(--nav-bg-color);;

  }
  .ui.tiny.attached.segment.navbar.active{
    background-color: var(--nav-bg-active-color);
  }

  .ui.inverted.vertical.labeled.icon.ui.push.left.visible.sidebar.active.menu{

    background-color: var(--nav-bg-active-color);
  }


  .ui.inverted.vertical.labeled.icon.ui.push.left.visible.sidebar.menu{

    background-color: var(--nav-bg-color);
  }

  .homeBody{
    margin-left:-5%;
    margin-right:-5%;

  }

  .ui.header.totals{
    font-weight:700 !important;
    font-size: 1.1rem !important;
  }

  th.totals{
    font-weight:700 !important;
    font-size: 1.1rem !important;
  }

  table.results{
    font-size: 1.0em !important;
  }

  .ui.header{
    font-family: 'Exo', sans-serif !important;
    font-weight:700 !important;
    font-size: 1.3em !important;
    line-height: 1.5 !important;
  }

  .ui.header.titleHead {
    font-family: 'Exo', sans-serif !important;
    font-weight:700 !important;
    font-size: 1.9em !important;
  }

  a.About{
    padding-left: 1em;
  }
  

}

@media all and (min-width:631px) {
    /* Hide the mobile menu */
    #mobile{
      display: none;
    }    
    /* Unhide the regular menu */
    #navbar{
          display:flex;
          justify-content:center;  
          
          padding-left: 15%;
          padding-right: 15%;
    }
    .home{
      position: relative;
      margin-top: -4em;
      margin-left:-35%;
      margin-right:-35%;
      height:350px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ui.menu{
      display:flex;

    }
    .ui.navbar.menu .item{
      flex:1 1 0px;
    }
 
    .homeBody{
      margin-left:-35%;
      margin-right:-35%;
  
    }

    .ui.header.totals{
      font-weight:700 !important;
      font-size: 1.6rem !important;
    }


    th.totals{
      font-weight:700 !important;
      font-size: 1.6rem !important;
    }

    table.results{
      font-size: 1.3em !important;
    }


    .ui.header{
      font-family: 'Exo', sans-serif !important;
      font-weight:700 !important;
      font-size: 1.7em !important;
      line-height: 1.1 !important;
    }

    .ui.header.titleHead {
      font-family: 'Exo', sans-serif !important;
      font-weight:700 !important;
      font-size: 3em !important;
    }
    

  }
 
  .deductions{
    display: none;
  }


  .home::before{
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-image: url(/src/Images/calcBG.jpg);
    background-repeat:no-repeat;
    background-size:cover;
    opacity: 0.35;
  }
  

  .homeBody > .ui.container{
    padding-top: 40px; 
    padding-bottom:40px;

  }

  h2.ui.header {
    position: relative;
    line-height: 0.9;
    text-align: center;
    color: rgb(0, 0, 0);
    padding: 10px;
  }

  .linkFull {
    display:inline-block;
    width:100%;
    height:100%;
    color:black !important;
}

.About{
  display: flex;
  color: rgba(255,255,255,.9);
  align-items: center
}

.content > #first{
  margin-top: 2em;
}
